import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby-link";
import Layout from "../components/layout";
import * as baseStyles from "./index.module.css";
import * as styles from "./getQuote.module.css";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function QuotePage() {
  const [defaultProduct, setDefaultProduct] = useState("");
  const [inputs, setInputs] = useState({});

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   const defaultProduct = params.get("product");
  //   setDefaultProduct(defaultProduct);
  // }, [])

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...inputs,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <Helmet>
        <title>Get Quote | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Get a Quote</h1>
        </div>

        <form
          method="post"
          className={styles.form}
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="quote-form"
          action="/"
          autoComplete
          onSubmit={handleSubmit}
        >
          <h4>
            Thank you for expressing an interest in our solution. Please fill
            out the form below, and we will get back to you as soon as possible.
          </h4>
          <input type="hidden" name="form-name" value="quote-form" />
          <input
            className={styles.field}
            type="text"
            name="firstName"
            placeholder="First Name"
            value={inputs.firstName || ""}
            onChange={handleChange}
            required
          />
          <input
            className={styles.field}
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={inputs.lastName || ""}
            onChange={handleChange}
            required
          />
          <input
            className={styles.field}
            type="text"
            name="businessName"
            placeholder="Business Name"
            value={inputs.businessName || ""}
            onChange={handleChange}
            required
          />
          <input
            className={styles.field}
            type="text"
            name="productName"
            placeholder="Product Name"
            value={inputs.productName || ""}
            onChange={handleChange}
            required
          />
          <input
            className={styles.field}
            type="email"
            name="email"
            placeholder="Email Address"
            value={inputs.email || ""}
            onChange={handleChange}
            required
          />
          <input
            className={styles.field}
            type="tel"
            name="phoneNumber"
            placeholder="Phone Number"
            value={inputs.phoneNumber || ""}
            onChange={handleChange}
            required
          />
          <input
            className={styles.submitButton}
            type="submit"
            value="Request Quote"
          />
        </form>
      </div>
    </Layout>
  );
}
